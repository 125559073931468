// remap jQuery to $
(function($){
    
    
   /**
    * PasswordVisiblityToggle
    * Created by: Prateek Rungta - <http://prateekrungta.com>
    *
    * Plugin that attaches a toggle to
    * unmask / mask the contents of password
    * fields on the page.
    *
    */
    $(function passwordVisibilityTogglePlugin() {
        $('input[type=password]').each(function initPasswordVisibilityToggle() {
            var $self = $(this),
                toggle_indicator = 'with-password-toggle',
                toggle_class = 'password-toggle',
                $button = $('<button class="title-small" type="button">Show</button>');
            
            $button.addClass(toggle_class);
            
            $self.parent()
                .on('click', 'button.' + toggle_class, function togglePasswordVisibility(e) {
                    if ($self.attr('type') == 'password') {
                        $self.attr('type', 'text');
                        $button.text('Hide');
                    } else {
                        $self.attr('type', 'password');
                        $button.text('Show');
                    }
                    e.preventDefault();
                });
            
            // Only make the toggle available when the field is populated
            $self.on('keyup', function togglePasswordVisibilityToggler(e) {
                if ($self.val() && !$self.hasClass(toggle_indicator)) {
                    $self
                        .addClass(toggle_indicator)
                        .after($button);
                }
                
                if ($self.val() === '' && $self.hasClass(toggle_indicator)) {
                    $self.removeClass(toggle_indicator);
                    $button.remove();
                }
            }).keyup();
        });
    });
    
    
})(this.jQuery);