/* main */




// Generic Toggle
function initToggles() {
    var settings = {
        triggerSelector: '.js-toggle, .js-reveal',
        targetAttr: 'tab-href',
        // scrollAttr: 'scroll-href',
        // scrollSelector: '.question',
        // scrollOffset: 0,
        activeSelector: '.question',
        activeClass: 'toggle-active'
    };
    
    // Generic toggles
    $(document).on('click', settings.triggerSelector, function toggleElement(e) {
        var $initiator = $(this),
            $target,
            $activeTarget,
            $scrollTarget;
        
        // Locate the toggle target
        if ($initiator.data(settings.targetAttr)) {
            $target = $($initiator.data(settings.targetAttr));
        } else if (
            this.nodeName == 'A' &&
            this.href.split('#',1)[0] === document.location.href.split('#',1)[0] &&
            this.hash) {
            $target = $(this.hash);
        }
        
        // Only proceed if we have a target
        if (! $target.length) return;
        
        // Toggle visibility
        $activeTarget = $target.closest(settings.activeSelector);
        if (!$activeTarget.length) $activeTarget = $target;
        if ($target.css('display') == 'none') {
            $target.show();
            $activeTarget.addClass(settings.activeClass);
            
        } else if ($initiator.is('.js-toggle')) {
            // Hide if the controller is a toggle
            $target.hide();
            $activeTarget.removeClass(settings.activeClass);
        }
        
        // Bring target into view
        // if ($initiator.data(settings.scrollAttr)) {
        //     $scrollTarget = $($initiator.data(settings.scrollAttr));
        // } else {
        //     $scrollTarget = $target.closest(settings.scrollSelector);
        // }
        // $scrollTarget.velocity("scroll", { offset: settings.scrollOffset });
        
        e.preventDefault();
    });
}

// Filters Toggle
function initFiltersToggle() {
    $(document).on( 'click', ".dropdown-trigger", function(e) {
        var $trigger = $(this),
            $target = $(this.hash ? this.hash : '');
        
        if ($target.length) {
            e.preventDefault();
            
            $target.slideToggle( "fast" );
            $trigger.toggleClass( "selected" );
        }
    });
}

// Checkbox Select-All Toggle
function initCheckboxSelectAllToggle() {
    var $toggleable_checkboxes = $( ".checkbox-toggleable" ),
        $checkbox_toggle       = $( ".checkbox-toggle-all" );
    
    $checkbox_toggle.click(function(e) {
        var $this = $(this);
        $toggleable_checkboxes.prop('checked', $this.prop('checked'));
    });
    
    $toggleable_checkboxes.click(function(e) {
        if($toggleable_checkboxes.length == $toggleable_checkboxes.not(':checked').length) 
            $checkbox_toggle.prop('indeterminate', false).prop('checked', false);
        else if(!$toggleable_checkboxes.not(':checked').length)
            $checkbox_toggle.prop('indeterminate', false).prop('checked', true);
        else
            $checkbox_toggle.prop('indeterminate', true);
    });
}

// Checkbox js-toggle
function initCheckboxJSToggle() {
    $(document).on('click', ".radio-js-toggle", function(e) {
        var $initiator = $(this),
            $siblings = $('input[name="'+$initiator.attr('name')+'"]').not($initiator);
            // $target = $($initiator.data('target'));
        
        $siblings.each(function() {
            var $this = $(this);
            if($this.data('target'))
                $($this.data('target')).slideUp(200).find('input').prop('disabled', true);
        });
        
        $($initiator.data('target')).slideDown(200).find('input').prop('disabled', false);
    });
}

// Ensure all Parlsey forms are initialised
function initParsley() {
    var $parsleyForms = $('form[data-parsley-validate]');
    if ($parsleyForms.length > 0) {
        $parsleyForms.parsley();
    }
}



// Pinned Header
function initPinnedHeader () {
    $('.toggle-scroll').toggleOnScroll();
}

function initJumpToTop () {
    var jump_selector = '#jump-to-top';
    var $window = $(window);
    var show_trigger;
    var hide_trigger;
    var last_position     = null;
    var current_position  = null;
    
    function recalculateTriggers() {
        show_trigger = Math.ceil( $window.height() * 0.7 );
        hide_trigger = Math.ceil( $('.site-wide-footer').offset().top - $window.height() );
    }
    recalculateTriggers();
    $window
        .load(recalculateTriggers)
        .resize(debounce(recalculateTriggers, 200));
    
    $window.scroll(debounce(function() {
        current_position = $window.scrollTop();
        
        // only execute if current position's status compared to
        // trigger has changed from the last position's status compared to trigger
        if (last_position === null || ((last_position < show_trigger) !== (current_position < show_trigger) || (last_position > hide_trigger) !== (current_position > hide_trigger)) ) {
            if (current_position > show_trigger && current_position < hide_trigger) {
                $(jump_selector).fadeIn();
            } else {
                $(jump_selector).fadeOut();
            }
        }
        last_position = current_position;
    }, 250));
    
    $(document).on('click', jump_selector + ' a', function (e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        e.preventDefault();
    });
}

// Google Analytics
function recordAnalytics() {
    if (window.gtag) {
        // Retrieve published date
        var date_published = document.body.querySelector("time[itemprop=datePublished]");
        window.gtag('event', 'user_engagement', {
            'date_published': date_published !== null ? date_published.dateTime : null,
        });
    }
}

// Google reCAPTCHA
function renderRecaptcha() {
    if(window.grecaptcha) {
        $('.g-recaptcha').each(function() {
            grecaptcha.render(this);
        });
    }
}



function main(isInitialLoad) {
    if (isInitialLoad === true) {
        
        // Only on INITIAL page load
        initToggles();
        initCheckboxJSToggle();
        initFiltersToggle();
        initJumpToTop();
        
    } else {
        
        // Only on PJAX page loads
        recordAnalytics();
        initParsley();
        renderRecaptcha();
    }
    
    // On every page load
    initPinnedHeader();
    initCheckboxSelectAllToggle();

}

// Call main when ready
$(function () {
    main(true);
});