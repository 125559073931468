// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});
    
    while (length--) {
        method = methods[length];
        
        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());



// Has Class polyfill
// from http://youmightnotneedjquery.com/#has_class
function hasClass(el, className) {
    if (el.classList) {
        return el.classList.contains(className);
    }
    return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
}



// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
// 
// from http://davidwalsh.name/javascript-debounce-function
// based on Underscore.js
function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}



/*** place any jQuery/helper plugins in here. ***/

// remap jQuery to $
(function($){



/**
 * Hide header on scroll down
 *
 * Adapted from https://medium.com/@mariusc23/hide-header-on-scroll-down-show-on-scroll-up-67bbaae9a78c
 * by Marius Craciunoiu
 */
$.fn.toggleOnScroll = function () {
    return this.each(function () {
        var $this = $(this),
            $window = $(window),
            $shadow = $('<div class="nav-shadow">'),
            init_class = 'nav-fixed',
            down_class = 'nav-down',
            up_class = 'nav-up',
            delta = 5,
            threshold_ratio = 2.5,
            scrollHandler,
            lastScrollTop,
            height;
        
        
        function onScroll() {
            if (!scrollHandler) {
                hasScrolled();
                scrollHandler = setTimeout(function () {
                    scrollHandler = false;
                    hasScrolled();
                }, 100);
            }
        }
        
        function hasScrolled() {
            var st = $window.scrollTop();
            
            // Make sure they scroll more than delta
            if (Math.abs(lastScrollTop - st) <= delta) {
                return;
            }
            
            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
            if (st > lastScrollTop && st > height) {
                // Scroll Down
                $this.removeClass(down_class).addClass(up_class);
                
            } else if (st + $window.height() < $(document).height()) {
                // Scroll Up
                $this.removeClass(up_class).addClass(down_class);
            }
            
            lastScrollTop = st;
        }
        
        
        // Called everytime the window's dimensions change
        function updateWindowCalculations() {
            lastScrollTop = $window.scrollTop();
            height = $this.outerHeight();
            
            if ($window.height() < height * threshold_ratio) {
                $shadow.remove();
                $this.removeClass(init_class, down_class, up_class);
                $window.off('scroll.toggleOnScroll', onScroll);
            } else {
                $window.on('scroll.toggleOnScroll', onScroll);
                $shadow.css('paddingTop', height);
                if (!$shadow.closest('body').length) {
                    $this.addClass(init_class);
                    $this.after($shadow);
                }
            }
        }
        
        $window
            .resize(debounce(updateWindowCalculations, 250))
            .load(updateWindowCalculations);
        updateWindowCalculations();
    });
};

})(this.jQuery);
