// Allow 'require-ing' at least one option from a list of checkboxes
// with the same name. Polyfill for modifying the default HTML behaviour
// which is requiring all checkboxes with the 'required' attribute
// 
// eg:
// <input type=checkbox name=eg value=a required data-required-any>
// <input type=checkbox name=eg value=b required data-required-any>
// <input type=checkbox name=eg value=c required data-required-any>

(function (window) {
    'use strict';
    
    function checkboxFixRequired(event) {
        var el = event.target;
        var selector = 'input[type=checkbox][data-required-any]';
        
        // only proceed if we have a matching checkbox
        if (!(el instanceof HTMLInputElement && el.matches(selector))) {
            return;
        }
        
        // disable 'required' on all unselected checkboxes with the same name attribute
        // when one is checked, otherwise enable 'required'
        Array.from(
            el.form.querySelectorAll(
              selector +
              '[name="' + el.name + '"]' +
              ':not(:checked)'
            )
        ).map(function (checkbox) {
            checkbox.required = !el.checked;
        });
    }
    
    document.addEventListener('change', checkboxFixRequired);
    
}(this));
